import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import L from 'leaflet';
import TestComponent from './TestComponent'
import { GrTest } from "react-icons/gr";


// Define custom icon
const customIcon = new L.Icon({
    iconUrl: require('./marker1.png'),
    iconSize: [41, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

export default function Map() {

    return (
        <div className="map-container" style={{height: '100%'}}>
            <MapContainer center={[4.7110, -74.0721]} zoom={13} scrollWheelZoom={false} className="map-container">
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[4.7110, -74.0721]}>
                    <Popup>
                        <TestComponent />
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}


