import axios from 'axios';
import { useEffect, useState } from 'react'
import Box from 'elements/Box';
import { Avatar, AvatarGroup, Button, Chip, CircularProgress, Dialog, IconButton, Modal, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {ProjectConfig} from 'Global';
import moment from 'moment';
import AlertDialog from 'elements/AlertDialog';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useSnackbar } from 'notistack';
import TuneIcon from '@mui/icons-material/Tune';
import Filters from 'elements/Filters/Filters';
import FiltersApplied from 'elements/Filters/FiltersApplied';

import 'assets/css/main.css'; 

import OrdersEditor from './OrdersEditor';
import { ApiRequest } from 'GlobalFunctions';

export default function OrdersList(props: any) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {setLoading} = props;

  useEffect(() => {
    setLoading(false);
  }, [])
  
  const [list, setList] = useState<any []>([]);
  const [total_pages, setTotalPages] = useState(0);

  const queryParams = new URLSearchParams(window.location.search);
  const currentPage=parseInt(localStorage.getItem('page') || "1");

  const [page, setPage] = useState(currentPage);
  const [filtersValues, setFiltersValues] = useState<any>([]);
  const [open, setOpen] = useState(false);

  const [openEditor, setOpenEditor] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadList();
  }, [page, filtersValues])

  /*=========================================================
  LOAD LIST
  =========================================================*/
  const loadList = async () => {
    setLoading(true);

    await ApiRequest({
      url: "/orders/list",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        page: page,
        filters: filtersValues,
      },
      setResponse: (response: any) => {
        setList(response.list);
        setTotalPages(response.total_pages);
        setLoading(false);
      },
    });
    
    localStorage.setItem('page', page.toString())
}


  /*=========================================================*/



  /*=========================================================
  DELETE ITEM
  =========================================================*/

  const handleDelete = (order_id:number) => {
    setActiveItem(order_id)
    setOpenAlert(true)
  }



  const deleteItem = () => {

    const body = {
      order_id: activeItem
    }

    axios.post(ProjectConfig.api_url+"/warehouses/deleteItem", body)
      .then((response) => {
        console.log(response.data);
        if(response.data.status === "success"){
          enqueueSnackbar('Bodega eliminada con éxito', { variant: 'success' });
          loadList();
        }
        
      })
      .catch((err) => {
        console.log(err.data);
      })
      .then(() => {
        setOpenAlert(false)
      })
  }
  

  /*=========================================================*/

// call global snackbar
  
const [openAlert, setOpenAlert] = useState(false);
const [activeItem, setActiveItem] = useState<any>(null);

const filters = [
  { type: "text", label: "Nombre cliente", name: "client_name", value: "" },
  { type: "text", label: "Nombre negocio", name: "business_name", value: "" },
  { type: "text", label: "NIT", name: "document", value: "" },
];

const setFilters = (tempFilters: any) => {
  setFiltersValues(tempFilters);
  localStorage.setItem('filters', JSON.stringify(tempFilters));
};

  
  return (
      <div id='UsersList' className='ModuleContainer'>
        <div className="ModuleWrapper"> 
          <div className="boxContainerHeader">
            
            <div className="boxContainerTitle">
              <h2 className="h2_title">Pedidos</h2>
              <span className="h2_subtitle">Lista de pedidos creados</span>
            </div>
            
            <div className="boxContainerActions">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setActiveItem(null)
                setOpenEditor(true)
              }}
              startIcon={<AddIcon />}
            >
              Crear pedido
            </Button>

            </div>
            
          </div>

          <IconButton color="inherit" aria-label="menu" onClick={() => setOpen(true)}>
            <TuneIcon />
          </IconButton>
          <Filters open={open} setOpen={setOpen} filters={filters} setFilters={setFilters} />
          <FiltersApplied filtersValues={filtersValues} setFiltersValues={setFiltersValues} />

          <div id='UsersListIn'>


            
              <Box id='WarehouseList' className='box100'>
              <TableContainer>
              <Table aria-label="sticky table"> 
                <TableHead>
                  <TableRow>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>ID</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Cliente</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Tipo</TableCell>
                    <TableCell  sx={{color:'#a8a8a8', borderBottom:"none"}}>Fecha / HORA</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {list.map((item,i) => {

                      const date = moment(item.date).format('DD/MM/YYYY');
                      const time = moment(item.date).format('HH:mm');

                      return(
                      <TableRow 
                        
                        key={i}
                        hover
                        role="checkbox"
                      >        

                      <TableCell>
                        {item.order_id}
                      </TableCell>              

                        <TableCell>
                        <span className='flex_title'>{item.client_name + " " + item.client_last_name}</span>
                        <span className='flex_subtitle'>{item.business_name}</span>
                        </TableCell>

                        <TableCell>
                          <Chip
                            label={item.order_type_label}
                            size='small'
                            sx={{
                              backgroundColor:
                                item.order_type === 'order'
                                  ? '#1976d2' // primary blue
                                  : item.order_type === 'quote'
                                    ? '#e7a42a' // orange
                                    : item.order_type === 'undelivered'
                                      ? '#cd3131' // red
                                      : undefined,
                              color: '#fff',
                            }}
                          />
                        </TableCell>

                        <TableCell>
                          <span className='flex_title'>{date}</span>
                          <span className='flex_subtitle'>{time}</span>
                        </TableCell>


                        <TableCell align="right">


                          {item.order_type !== 'undelivered' &&
                            <Button 
                              size="small"
                              variant="contained"
                              color="primary"
                              style={{"marginRight": "10px"}}
                              startIcon={<ModeEditIcon />}
                              onClick={() => {
                                setActiveItem(item.order_id)
                                setOpenEditor(true)
                              }}
                            >
                              Detalle
                            </Button>
                          }


                          
                          {(localStorage.getItem('role_id') === "1" || localStorage.getItem('role_id') === "2") &&
                          <IconButton aria-label="delete"  color="primary" onClick={() => handleDelete(item.order_id)}>
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                          }
                      </TableCell>
                      </TableRow>
                    )})}
                </TableBody>
              </Table>
            </TableContainer>
            
            <Pagination count={total_pages} page={page}  onChange={(event, value) => {
              setPage(value)
            }
            } 
            color="primary"
            style={{"marginTop": "20px","alignSelf": "center"}}
            />
            </Box>
  
          </div>
        </div>

        <AlertDialog
            open={openAlert}
            setOpen={setOpenAlert}
            subtitle="¿Está seguro que desea eliminar esta bodega?"
            send={deleteItem}
        />



        <OrdersEditor
          setLoading={setLoading}
          openEditor={openEditor} 
          setOpenEditor={setOpenEditor}
          order_id={activeItem} 
          loadList={loadList}
          handleClose={() => setOpenEditor(false)}
        />



    </div>
  );
};