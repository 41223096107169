import { Button, Dialog, Divider, FormControlLabel, IconButton, Switch, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { ProjectConfig } from "Global";
import AutocompleteWarehouses from "elements/AutocompleteWarehouses";
import AutocompleteField from "elements/AutocompleteField";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { ApiRequest } from "GlobalFunctions";
import { min } from "lodash";

export default function OrdersEditor(props: any) {
  const { setLoading, openEditor, setOpenEditor, order_id, loadList } = props;
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    order_id: "",
    post_date: "",
    products: [] as any,
    client_id: "",
    longitude: "",
    latitude: "",
}

console.log("order_id", order_id);


useEffect(() => {
  if(openEditor){
    setFormValues(defaultValues);
    if (order_id) {
      loadDetails();
    }
  }
}, [openEditor, order_id]);

const handleClose = () => {
  setFormValues(defaultValues);
  setOpenEditor(false);
  setIsEditable(false); // Set isEditable to false when closing
};

const loadDetails = async () => {
  ApiRequest({
    url: "/orders/details",
    method: "get",
    headers: {
      ltkn: localStorage.getItem("ltkn"),
    },
    query: {
      order_id,
    },
    setResponse: (response: any) => {
      setFormValues(response.data);
    },
  });
}

  const [formValues, setFormValues] = useState(defaultValues);

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    if (order_id) {
      setIsEditable(false);
    } else {
      setIsEditable(true); // Set isEditable to true for new orders
    }
  }, [order_id]);

  useEffect(() => {
    if (order_id) {

      loadDetails();
    } else {
      setFormValues(defaultValues);
    }
  }, [order_id]);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const [list, setList] = useState<any[]>([]);
  /*=========================================================
  GET LIST
  =========================================================*/
  const getList = async () => {
    await ApiRequest({
      url: "/products/list",
      method: "get",
      headers: {
          ltkn: localStorage.getItem('ltkn')
      },
      query: {
          full_list: true,
      }, 
      setResponse: (response: any) => {
          setList(response.list);
      },
    });
  }

  useEffect(() => {
    getList();
  }, []);


  /*===============================================================
  SUBMIT FORM
  ===============================================================*/
  const handleSubmit = async () => {

    /*=======================================
    check mandatory fields
    =======================================*/
    if (formValues.products.length === 0) {
      return enqueueSnackbar("Debe agregar al menos un producto", { variant: "error" });
    }

  

    if (formValues.products.some((product: any) => product.product_id === null)) {
      return enqueueSnackbar("Seleccione un producto", { variant: "error" });
    }

    if (formValues.products.some((product: any) => product.quantity === "")) {
      return enqueueSnackbar("Ingrese la cantidad de productos", { variant: "error" });
    }





    setLoading(true);
    const body = new FormData();
    body.append("formValues", JSON.stringify(formValues));
    console.log("formValues", formValues);

    await ApiRequest({
      url: "/orders/addedit",
      method: "post",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      body: body,
      setResponse: (response: any) => {
        if (response.status === "success") {
          enqueueSnackbar("Guardado con éxito", { variant: "success" });
          setOpenEditor(false);
          loadList();
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
      },
    });
  };

  const productDefault = {
    product_id: null,
    package_quantity: "",
    units: ""
  }


  const addProduct = () =>{
    console.log("addProduct")
    
    let newProducts = [...formValues.products, productDefault];
    setFormValues({...formValues, products: newProducts});
    
  }


  const getReport = async (download = "") => {
    console.log(formValues);
    setLoading(true);
  

    await ApiRequest({
      url: "/reports/getReport",
      method: "get",
      headers: {
        ltkn: localStorage.getItem("ltkn"),
      },
      query: {
        order_id,
        exportType: "getMovement",
        download,
      },
      setResponse: (response: any) => {
        if (response.status === "success") {
          if (download === "pdf") {
            window.open(response.pdfUrl, "_blank");
          }
          if (download === "excel") {
            window.open(response.fileUrl, "_blank");
          }
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
        setLoading(false);
        
      },
    });
  };

  // Add state variables for totals
  const [totalProducts, setTotalProducts] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    // Calculate totals whenever products change
    calculateTotals();
  }, [formValues.products]);

  const calculateTotals = () => {
    let totalProducts = formValues.products.length;
    let subtotal = formValues.products.reduce((sum: number, item: any) => {
      let price = item.product_info?.client_product_price || 0;
      let quantity = item.quantity || 0;
      return sum + price * quantity;
    }, 0);
    let taxes = formValues.products.reduce((sum: number, item: any) => {
      let tax = item.product_info?.taxes || 0;
      let quantity = item.quantity || 0;
      return sum + tax * quantity;
    }, 0);
    let total = subtotal + taxes;
    setTotalProducts(totalProducts);
    setSubtotal(subtotal);
    setTaxes(taxes);
    setTotal(total);
  };

  return (
    <Dialog open={openEditor} onClose={handleClose}>
      <div className="DialogContainer" >
        <div className="ModuleWrapper">
          <div className="boxContainerHeader">
            <div className="boxContainerTitle">
              <h2 className="h2_title">Pedido</h2>
              <span className="h2_subtitle">Editar</span>
            </div>

           

            <FormControlLabel
              control={
                <Switch
                  checked={isEditable}
                  onChange={() => setIsEditable(!isEditable)}
                  color="primary"
                />
              }
              label="Editar"
            />
          </div>

          <div className="input_group">
            
            {formValues.client_id}
          <AutocompleteField
              label="Cliente"
              endpoint="/clients/list"
              value={formValues.client_id}
              onChange={(data: any) => {
                setFormValues({ ...formValues, client_id: data.value });
              }}
              disabled={!!order_id || !isEditable}
              fullwidth={true}
              sx={{ minWidth: "500px" }}
            />

            
            <TextField
              id="post_date"
              name="post_date"
              label="Post fechar"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }} // Change here
              value={formValues.post_date}
              onChange={handleInputChange}
              disabled={!isEditable}
            />
          </div>

          <div className="productList">
            <h3>Productos</h3>
            <div className="productListItems">
              {formValues.products.map((product: any, index: number) => {


                return (
                <>
                  <div key={index} className="productListItem">
                    <div className="productListItemName">
                      
                      <AutocompleteField
                        label="Producto"
                        endpoint="/products/list"
                        renderValues={["product_code", "label"]}
                        value={product.product_id}
                        preloadedList={list}
                        onChange={(data: any) => {
                          let newProducts = [...formValues.products];
                          newProducts[index] = {
                            ...newProducts[index],
                            product_id: data.value,
                            product_info: data,
                          };
                          setFormValues({ ...formValues, products: newProducts });
                        }}
                        disabled={!isEditable}
                      />
                    </div>
                  
                      <TextField
                      id="quantity"
                      name="quantity"
                      label="Cantidad"
                      type="number"
                      required
                      value={product.quantity}
                      onChange={(e) => {
                        let newProducts = [...formValues.products];
                        newProducts[index] = {
                          ...newProducts[index],
                          quantity: e.target.value,
                        };
                        setFormValues({ ...formValues, products: newProducts });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          addProduct();
                        }
                      }}
                      disabled={!isEditable}
                    />

                      {/* delete row*/}
                      <DeleteOutlineOutlinedIcon 
                      color={isEditable ? 'primary' : 'disabled'}
                        onClick={() => {
                          if (isEditable) {
                            let newProducts = formValues.products.filter((_: any, i: any) => i !== index);
                            setFormValues({ ...formValues, products: newProducts });
                          }
                        }}
                        style={{ cursor: isEditable ? 'pointer' : 'not-allowed' }}
                      />
                      
                  </div>

                  <div className="priceRow">
                    <div className="priceRowItem">
                      <span className="priceRowLabel">Precio</span>
                      <span className="priceRowValue">
                        ${Number(product.product_info?.client_product_price || 0).toLocaleString("es-CO", { maximumFractionDigits: 0 })}
                      </span>
                    </div>
                    <div className="priceRowItem">
                      <span className="priceRowLabel">Impuestos:</span>
                      <span className="priceRowValue">
                        ${Number(product.product_info?.taxes || 0).toLocaleString("es-CO", { maximumFractionDigits: 0 })}
                      </span>
                    </div>
                    <div className="priceRowItem">
                      <span className="priceRowLabel">Total:</span>
                      <span className="priceRowValue">
                        ${Number(
                          Math.round(
                            (product.product_info?.client_product_price || 0) +
                            (product.product_info?.taxes || 0)
                          ) *
                          (product.quantity || 0)
                        ).toLocaleString("es-CO", { maximumFractionDigits: 0 })}
                      </span>
                    </div>
                  </div>
                  <Divider />
                </>
              )})}
              {}
              <div className="addProductButton">
              <IconButton 
                aria-label="add" 
                onClick={addProduct} 
                sx={{ 
                  backgroundColor: "#1C3B41", 
                  padding: '10px', 
                  color: '#fff', 
                  '&:hover': { backgroundColor: '#24545d' } 
                }}
                disabled={!isEditable}
              >
                <AddIcon />
              </IconButton>
              </div>
              
            </div>
            <div className="totalRow">
              <span className="totalRowItem">{totalProducts} Productos</span>
              <span className="totalRowItem"><b>Subtotal:</b> ${Number(subtotal).toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
              <span className="totalRowItem"><b>Impuestos:</b> ${Number(taxes).toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
              <span className="totalRowItem"><b>Total a pagar:</b> ${Number(Math.round(total)).toLocaleString("es-CO", { maximumFractionDigits: 0 })}</span>
            </div>
          </div>

          <div className="dialogButtons">
            <Button onClick={handleClose} variant="outlined">
              Cerrar
            </Button>
            <Button onClick={handleSubmit} variant="contained" disabled={!isEditable}>
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
